<template>
  <v-dialog
    @click:outside="close"
    v-model="$store.state.showSupport"
    max-width="900px"
  >
    <v-card class="rounded-xl px-6 py-4">
      <v-card-title>Support 🛟</v-card-title>
      <v-card-text>
        <ToggleButtonSelect
          :options="options"
          v-model="selectedOption"
          class="mb-4"
        />
        <v-expand-transition>
          <div v-if="selectedOption === 'MEETING'">
            <span class="d-block mb-3">
              Schedule a coaching session to enhance your experience with the
              platform.
            </span>
            <ul>
              <li>
                <b> Berivan's Coaching Session </b> -
                <a
                  href="https://go.oncehub.com/BerivanLinkedClient"
                  target="_BLANK"
                >
                  https://go.oncehub.com/BerivanLinkedClient
                </a>
              </li>
              <li>
                <b> Klaudia's Coaching Session </b> -
                <a
                  href="https://go.oncehub.com/KlaudiaLinkedClient"
                  target="_BLANK"
                >
                  https://go.oncehub.com/KlaudiaLinkedClient
                </a>
              </li>
              <li>
                <b> Eriona's Coaching Session </b> -
                <a href="https://go.oncehub.com/Eriona" target="_BLANK">
                  https://go.oncehub.com/Eriona
                </a>
              </li>
              <li>
                <b> Dea's Coaching Session </b> -
                <a href="https://go.oncehub.com/DeaJanuzi" target="_BLANK">
                  https://go.oncehub.com/DeaJanuzi
                </a>
              </li>
              <li>
                <b> Loresa's Coaching Session </b> -
                <a href="https://go.oncehub.com/LoresaBeka" target="_BLANK">
                  https://go.oncehub.com/LoresaBeka
                </a>
              </li>
            </ul>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="selectedOption === 'ONBOARDING'">
            <span class="d-block mb-3">
              Schedule an onboarding session to get to know the platform better.
            </span>
            <a
              href="https://go.oncehub.com/LinkedClientOnboarding"
              target="_BLANK"
            >
              https://go.oncehub.com/LinkedClientOnboarding
            </a>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="selectedOption === 'GENERAL'">
            <span class="d-block mb-3">
              Need help or have questions? We're here to assist you!
            </span>
            <v-textarea
              label="Support question"
              v-model="generalReport"
              counter
              outlined
              :rules="rules"
            ></v-textarea>
            <v-btn
              :loading="loading"
              :disabled="loading || generalReportSended"
              block
              depressed
              color="primary"
              @click="sendGeneralReport"
            >
              <span v-if="!generalReportSended">Send general report</span>
              <span v-else>Your message was sent</span>
            </v-btn>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="selectedOption === 'BUG_REPORT'">
            <span class="d-block mb-3">
              Report a bug to help us improve and provide you with a better
              platform experience. We kindly request that you provide a detailed
              description of where and how it occurred. This will help our team
              to fix the issue as quickly as possible. Thank you!
            </span>
            <v-textarea
              label="Bug report"
              v-model="bugReport"
              counter
              outlined
              :rules="rules"
            ></v-textarea>
            <v-btn
              :loading="loading"
              :disabled="loading || bugReportSended"
              block
              depressed
              color="primary"
              @click="sendBugReport"
            >
              <span v-if="!bugReportSended">Send bug report</span>
              <span v-else>Your message was sent</span>
            </v-btn>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="selectedOption === 'CANCEL' && !cancelRequest">
            <span class="d-block mb-3 mt-8 text-center">
              <h3><b>Are you sure you want to cancel your subscription?</b></h3>
            </span>
            <div style="width: 100%; margin: 20px auto" class="text-center">
              <v-btn
                outline
                depressed
                @click="sendCancellationRequest()"
                class="mx-4 text-center"
              >
                <span>Yes, I want to leave Elsie 😭</span>
              </v-btn>
              <v-btn
                depressed
                color="primary"
                @click="close()"
                class="mx-4 text-center"
              >
                <span>No, I would never leave Elsie ❤️</span>
              </v-btn>
            </div>
          </div>
          <div v-if="selectedOption === 'CANCEL' && cancelRequest">
            <span class="d-block mb-3 mt-8 text-center">
              <h3><b>Sorry to see you go :(</b></h3>
              <p>
                Your request to cancel has been accepted. According to our Terms
                &amp; Conditions, your subscription will be cancelled 14 days
                from submitting this request.
              </p>
              <p>
                A member of our support team will contact you shortly to confirm
                your cancellation.
              </p>
            </span>
          </div>
        </v-expand-transition>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ToggleButtonSelect from "@/components/ToggleButtonSelect";
import { RULE_FIELD_NOT_EMPTY } from "../const";

export default {
  components: {
    ToggleButtonSelect,
  },
  data() {
    return {
      loading: false,
      generalReport: "",
      generalReportSended: false,
      bugReport: "",
      bugReportSended: false,
      cancelRequest: false,
      rules: [
        RULE_FIELD_NOT_EMPTY,
        (value) =>
          (typeof value === "string" && value.length < 2048) ||
          "Maximum characters set to 2048",
      ],
      selectedOption: "MEETING",
      options: [
        {
          icon: "mdi-face-agent",
          text: "Schedule a coaching session",
          value: "MEETING",
        },
        {
          icon: "mdi-lifebuoy",
          text: "Schedule an onboarding session",
          value: "ONBOARDING",
        },
        {
          icon: "mdi-file-document",
          text: "Contact support",
          value: "GENERAL",
        },
        {
          icon: "mdi-bug",
          text: "Report a bug",
          value: "BUG_REPORT",
        },
        {
          icon: "mdi-emoticon-sad-outline",
          text: "Cancel your subscription",
          value: "CANCEL",
        },
      ],
    };
  },

  methods: {
    close() {
      this.$store.dispatch("setShowSupport", false);
      this.loading = false;
      this.generalReport = "";
      this.bugReportSended = false;
      this.generalReportSended = false;
      this.bugReport = "";
      this.selectedOption = "MEETING";
    },
    /**
     * Send general report
     */
    async sendGeneralReport() {
      if (this.loading) return;

      if (this.generalReport.length === 0) {
        this.$message({
          type: "warning",
          msg: "Your message cannot be empty. Please fill in the form and try again",
        });

        return;
      }

      if (this.generalReport.length > 2048) {
        this.$message({
          type: "warning",
          msg: "Your message is too long. Please shorten it and try again",
        });

        return;
      }

      this.loading = true;

      try {
        await this.$http.post("/support/general", {
          message: this.generalReport,
        });

        this.$message({
          type: "success",
          msg: "Your request has been submitted",
        });

        this.generalReport = "";
        this.generalReportSended = "";
        this.$store.dispatch("setShowSupport", false);
      } catch (error) {
        console.error(error);
        this.$message({
          type: "error",
          msg: "Your requested failed to be submitted. Please try again or send an email to support@linkedclient.com",
        });
      }

      this.loading = false;
    },
    /**
     * Send general report
     */
    async sendBugReport() {
      if (this.loading) return;

      if (this.bugReport.length === 0) {
        this.$message({
          type: "warning",
          msg: "Your message cannot be empty. Please fill in the form and try again",
        });

        return;
      }

      if (this.bugReport.length > 2048) {
        this.$message({
          type: "warning",
          msg: "Your message is too long. Please shorten it and try again",
        });

        return;
      }

      this.loading = true;

      try {
        await this.$http.post("/support/bug", {
          message: this.bugReport,
        });

        this.$message({
          type: "success",
          msg: "Your request has been submitted",
        });

        this.$store.dispatch("setShowSupport", false);
        this.bugReportSended = true;
        this.bugReport = "";
      } catch (error) {
        console.error(error);
        this.$message({
          type: "error",
          msg: "Your requested failed to be submitted. Please try again or send an email to support@linkedclient.com",
        });
      }

      this.loading = false;
    },
    /**
     * Send a cancellation request
     */
    async sendCancellationRequest() {
      if (this.loading) return;

      this.cancelRequest = true;
      this.loading = true;

      try {
        await this.$http.post("/support/cancel", { message: "cancel" });

        this.$message({
          type: "success",
          msg: "Your request has been submitted",
        });
      } catch (error) {
        console.error(error);
        this.$message({
          type: "error",
          msg: "Your request failed to be submitted. Please try again or send an email to support@linkedclient.com",
        });
      }

      this.loading = false;
    },
  },
};
</script>
