export const SURVEY_STEPS = [
  {
    title: "Welcome {{USERNAME}}!",
    description:
      "Let’s personalize your experience on our platform. To do this, we need to understand your business and personal preferences. The data you provide will enhance our AI's ability to deliver relevant and personalized responses.<br/> <b>If a question doesn't apply to you, simply leave the field empty — there's no need to write 'not relevant' or anything similar.</b> <br/><br/> Let's get started!",
    step: 1,
    buttons: {
      next: "Begin personalization",
      prev: "Do it later",
    },
  },
  {
    title: "Setup",
    description:
      "Share your professional details to tailor your experience and improve your results on our platform.",
    step: 2,
    questions: [
      {
        type: "textarea",
        label: "Company name *",
        question: "Company name *",
        key: "companyName",
        hint: "Your company name.",
        required: true,
      },
      {
        type: "textarea",
        label: "Location *",
        question: "Location *",
        key: "companyLocation",
        hint: "Write down, where you are based.",
        required: true,
      },
      {
        type: "textarea",
        label: "Your Title in the Company *",
        question: "Your Title in the Company *",
        key: "companyTitle",
        hint: "Your job title or position within the company.",
        required: true,
      },
      {
        type: "textarea",
        label: "Industry *",
        question: "Industry *",
        key: "industry",
        hint: "Provide the industry your company operates in.",
        required: true,
      },
      {
        type: "select",
        label: "Company size *",
        question: "Company size (Headcount) *",
        key: "companySize",
        hint: "Choose the range the represents the total number of employees in your company.",
        answers: [
          "1-10",
          "11-50",
          "51-100",
          "101-500",
          "501-1000",
          "1001-5000",
          "5001+",
        ],
        required: true,
      },
      {
        type: "textarea",
        label: "Target Audience *",
        question: "Target Audience *",
        key: "targetAudience",
        hint: "Describe the primary group of customers or clients you serve or intend to reach.",
        required: true,
      },
      {
        type: "textarea",
        label: "Linkedin Profile *",
        question: "Provide a link to your LinkedIn profile. *",
        key: "linkedinProfile",
        required: true,
      },
      {
        type: "textarea",
        label: "Your Website *",
        question: "Provide a link to your company website. *",
        key: "companyWebsite",
        required: true,
      },
      {
        type: "textarea",
        label: "Competitors",
        question: "Provide three of the biggest competitors for your business.",
        key: "competitors",
        required: false,
      },
    ],
    buttons: {
      next: "Continue",
      prev: "Go back",
    },
  },
  {
    title: "Sales Strategy and Growth Goals",
    description:
      "Share your sales strategy and growth plans to personalize your experience and improve your results.",
    step: 3,
    questions: [
      {
        type: "textarea",
        label: "Your Goal with LinkedClient *",
        question: "Your Goal with LinkedClient *",
        key: "goal",
        hint: "Write about what you aim to achieve by using LinkedClient.",
        required: true,
      },
      {
        type: "select",
        label: "Number of Desired Meetings per Month *",
        question: "Number of Desired Meetings per Month *",
        key: "desiredMeetings",
        answers: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15+",
        ],
        required: true,
      },
      {
        type: "textarea",
        label: "Sales Growth *",
        question: "How have you worked on growing your sales up until now? *",
        key: "salesGrowth",
        required: true,
      },
      {
        type: "textarea",
        label: "Sales Strategy",
        question:
          "How much do you want to scale your sales during the next 24 months?",
        key: "salesStrategy",
        required: false,
      },
      {
        type: "textarea",
        lables: "Current Growth",
        question: "How much has your company grown in the last two years?",
        key: "currentGrowth",
        required: false,
      },

      {
        type: "textarea",
        label: "Time to First Sale",
        question:
          "How long does it usually take from the first contact with a potential customer until you sell for the first time?",
        key: "timeToFirstSale",
        required: false,
      },
      {
        type: "textarea",
        label: "Conversion Rate",
        question:
          "Do you know your conversion rate for meetings or quotations?",
        key: "conversionRate",
        required: false,
      },
    ],
    buttons: {
      next: "Continue",
      prev: "Go back",
    },
  },
  {
    title: "Offer & Benefits",
    description:
      "This information is essential for our AI (Elsie) to grasp the essence of your business, enabling it to effectively participate in conversations, understand your sales pitch and generate personalized campaign messages.",
    step: 4,
    questions: [
      {
        type: "textarea",
        label: "Brand description",
        question: "Provide a brief description of your brand.",
        key: "brandDescription",
        hint: "Helps Elsie to align its language with your brand’s identity.",
        required: false,
      },
      {
        type: "textarea",
        label: "Company offer",
        question:
          "What specific services or products does your business offer? ",
        key: "companyOffer",
        hint: "Enables Elsie to accurately represent and suggest your offerings.",
        required: false,
      },
      {
        type: "textarea",
        label: "Longterm offer",
        question:
          "Describe your long-term offer, if it differs from you current services or products. ",
        key: "longtermOffer",
        hint: "Assists Elsie in understanding your future direction for more forward-thinking guidance, specifically for future follow-ups.",
        required: false,
      },
    ],
    buttons: {
      next: "Continue",
      prev: "Go back",
    },
  },
  {
    title: "Unique Selling Points",
    description:
      "Identifying what makes your offer special allows our AI (Elsie) to effectively highlight these aspects in interactions.",
    step: 5,
    questions: [
      {
        type: "textarea",
        label: "Unique selling point",
        question:
          "What are the key unique selling points or benefits of your offer?",
        key: "uniqueSellingPoint",
        hint: "Helps Elsie to emphasize these points in conversations and recommendations.",
        required: false,
      },
      {
        type: "textarea",
        label: "Case study",
        question:
          "Do you have a link to a case study or to your website for more detailed information?",
        key: "caseStudy",
        hint: "Allows Elsie to include the link as a recommendation in messages.",
        required: false,
      },
      {
        type: "textarea",
        label: "FAQ / Objection handling / Prompting",
        question:
          "Could you share some frequently asked questions or address common objections with regard to your offer? In this section, you can also write your own instructions for Elsie’s behavior.",
        key: "faq",
        hint: "Prepares Elsie to handle queries and concerns effectively.",
        required: false,
      },
    ],
    buttons: {
      next: "Continue",
      prev: "Go back",
    },
  },
  {
    title: "User Personality & Background",
    description:
      "Understanding your professional and personal background enables our AI to tailor its communication style.",
    step: 6,
    questions: [
      {
        type: "textarea",
        label: "Job title & Work nature",
        question:
          "What is your current job title and the nature of your work? Where are you based?",
        key: "jobTitleAndWorkNature",
        hint: "Provides context for Elsie to adjust its responses based on your role and location.",
        required: false,
      },
      {
        type: "textarea",
        label: "Professional skills & Experience",
        question:
          "Can you briefly describe your key professional skills, relevant background, and experience?",
        key: "professionalSkillsAndExperience",
        hint: "Helps Elsie to align its advice and suggestions with your expertise.",
        required: false,
      },
      {
        type: "textarea",
        label: "Special education qualifications",
        question:
          "Are there any special educational qualifications you've picked up along the way that would matter in terms of your business offer?",
        key: "specialEducationQualifications",
        hint: "Enables Elsie to consider and use your educational background in its interactions if relevant.",
        required: false,
      },
    ],
    buttons: {
      next: "Continue",
      prev: "Go back",
    },
  },
  {
    title: "Personal interests",
    description:
      "Your personal interests and networking style inform Elsie's approach to creating a more engaging and personalized outreach.",
    step: 7,
    questions: [
      {
        type: "textarea",
        label: "Personal interests & Hobbies",
        question:
          "What are your personal interests or hobbies outside of work? How do these interests contribute to your professional life, if at all?",
        key: "personalInterestsAndHobbies",
        hint: "Allows Elsie to understand you on a more personal level and implement this knowledge in sales outreach intersections., when relevant.",
        required: false,
      },
      {
        type: "textarea",
        label: "Networking & Collaboration style",
        question:
          "What's your style for networking and collaboration on LinkedIn, and are there particular professionals or industries you're keen on maintaining relationships with?",
        key: "networkingAndCollaborationStyle",
        hint: "Guides Elsie in keeping relevant and meaningful connections with prospects, even if they don’t mean immediate collaboration opportunities.",
        required: false,
      },
      {
        type: "textarea",
        label: "Approach to work-career balance",
        question:
          "Can you share your overall approach to your work and career and balance between personal life?",
        key: "approachToWorkCareerBalance",
        hint: "Helps Elsie understand your work-life philosophy for more relevant approach.",
        required: false,
      },
    ],
    buttons: {
      next: "Continue",
      prev: "Go back",
    },
  },
  {
    title: "Personality Type",
    description:
      "Knowing your personality type enables our Elsie to tailor its messaging style to match your preferences, making interactions feel more natural.",
    step: 8,
    questions: [
      {
        label: "Personality type",
        type: "select",
        answers: [
          "Architect (INTJ-A/INTJ-T)",
          "Logician (INTP-A/INTP-T)",
          "Commander (ENTJ-A/ENTJ-T)",
          "Debater (ENTP-A/ENTP-T)",
          "Advocate (INFJ-A/INFJ-T)",
          "Mediator (INFP-A/INFP-T)",
          "Protagonist (ENFJ-A/ENFJ-T)",
          "Campaigner (ENFP-A/ENFP-T)",
          "Logistician (ISTJ-A/ISTJ-T)",
          "Defender (ISFJ-A/ISFJ-T)",
          "Executive (ESTJ-A/ESTJ-T)",
          "Consul (ESFJ-A/ESFJ-T)",
          "Virtuoso (ISTP-A/ISTP-T)",
          "Adventurer (ISFP-A/ISFP-T)",
          "Entrepreneur (ESTP-A/ESTP-T)",
          "Entertainer (ESFP-A/ESFP-T)",
        ],
        question:
          'Do you know your personality type based on the 16 Personalities test? If not, you can take the test <a target="_blank" href="https://www.16personalities.com/">here.</a>',
        key: "personalityType",
        hint: "This information helps our Elsie write messages as if they were from a similar personality perspective.",
        required: false,
      },
    ],
    buttons: {
      next: "Continue",
      prev: "Go back",
    },
  },
  {
    title: "Summarization",
    description:
      "Thank you for providing this information! We're now ready to offer you a personalized experience on our platform 🙂",
    step: 9,
    buttons: {
      next: "Save survey & Exit",
      prev: "Go back",
    },
  },
];
